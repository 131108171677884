<template>
  <div class="text-center pa-5 mt-10">
    <div class="text-medium">現在サーバのメンテナンス中です。時間をおいて再度お試しください。</div>
    <div>{{ getMaintenanceMessage() }}</div>
  </div>
</template>

<script>
import { getMaintenanceMessage } from '@/utils/app'
export default {
  methods: {
    getMaintenanceMessage
  }
}
</script>
